<template>
  <div class="px-6 py-6 lg:px-8 bg-white">
    <div>
      <nav class="flex h-9 items-center justify-between" aria-label="Global">
        <div class="flex lg:min-w-0 lg:flex-1" aria-label="Global">
          <NuxtLink to="/" class="-m-1.5 p-1.5">
            <span class="sr-only">Kroznik</span>
            <img class="h-8" src="~/assets/svgs/logo.svg" alt="Logo" />
          </NuxtLink>
        </div>
        <div class="flex lg:hidden">
          <button
            type="button"
            class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = true"
          >
            <span class="sr-only">Odpri glavni meni</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-end">
          <template v-if="userStore.isLoggedIn === false">
            <NuxtLink
              :to="Routes.Login"
              class="rounded-lg px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm ring-1 ring-gray-900/10 hover:bg-green-lightest transition mr-2"
            >
              Vpiši se
            </NuxtLink>
            <NuxtLink
              :to="Routes.Register"
              class="rounded-lg bg-green-main px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ring-1 ring-gray-900/10 hover:bg-green-dark transition"
            >
              Ustvari poslovni račun
            </NuxtLink>
          </template>
          <template v-else>
            <NuxtLink
              :to="Routes.Dashboard"
              class="rounded-lg bg-green-main mx-3 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm ring-1 ring-gray-900/10 hover:ring-gray-900/20"
            >
              Nadzorna plošča
            </NuxtLink>
          </template>
        </div>
      </nav>
      <Dialog as="div" :open="mobileMenuOpen" @close="mobileMenuOpen = false">
        <DialogPanel
          focus="true"
          class="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
        >
          <div class="flex h-9 items-center justify-between">
            <div class="flex">
              <a href="#" class="-m-1.5 p-1.5">
                <span class="sr-only">Kroznik</span>
                <img class="h-8" src="~/assets/svgs/logo.svg" alt="logo" />
              </a>
            </div>
            <div class="flex">
              <button
                type="button"
                class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                @click="mobileMenuOpen = false"
              >
                <span class="sr-only">Zapri meni</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div v-if="userStore.isLoggedIn === false" class="py-6">
                <NuxtLink
                  :to="Routes.Login"
                  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10 hover"
                  @click="mobileMenuOpen = false"
                >
                  Vpiši se
                </NuxtLink>
                <NuxtLink
                  :to="Routes.Register"
                  class="-mx-3 bg-green-main block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-white hover:bg-gray-400/10 hover"
                  @click="mobileMenuOpen = false"
                >
                  Ustvari poslovni račun
                </NuxtLink>
              </div>
              <div v-else>
                <NuxtLink
                  :to="Routes.Dashboard"
                  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10 hover"
                >
                  Nadzorna plošča
                </NuxtLink>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </div>
  </div>
  <slot />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Routes } from '~/routing/routes'
import { useUserStore } from '~/stores/user.store'
import { Configuration, HealthcheckApi } from '~/client_api'
import { axiosInstance } from '~/client_api/exios'
import { useHealthcheckStore } from '~/stores/healthcheck'
import { HEALTHCHECK_INTERVAL } from '~/utils/healthcheck'

const mobileMenuOpen = ref(false)
const userStore = useUserStore()
const healthcheckStore = useHealthcheckStore()

let timer: string | number | NodeJS.Timer | null | undefined = null
onMounted(() => {
  timer = setInterval(() => {
    new HealthcheckApi(new Configuration(), '', axiosInstance)
      .healthcheckRetrieve()
      .then(() => {
        healthcheckStore.hasError = false
      })
      .catch(() => {
        healthcheckStore.hasError = true
      })
  }, HEALTHCHECK_INTERVAL)
})
onUnmounted(() => {
  clearInterval(timer)
})
</script>

<style scoped></style>
